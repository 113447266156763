import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import { IconCalendarMonth, IconCoin, IconHash, IconToggleLeft } from '@tabler/icons-react'
import { keyBy } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { Apps } from '../../../../types/App'
import { FieldDefinition } from '../../../../types/FieldDefinition'
import {
  PersistedFieldDefinition,
  useCreateFieldDefinition,
  useUpdateFieldDefinition
} from '../../../data/use-field-definitions'
import { useFieldMappings } from '../../../data/use-field-mappings'
import { AutosizedTextarea } from '../../../ui/AutosizedTextarea'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import { Iconify } from '../../../ui/Iconify'
import { TextIcon } from '../../../ui/icons'
import SelectInput from '../../../ui/SelectInput'
import { FilterItem, grouped } from '../../accounts/facets/categories'

function deriveType(item: FilterItem | null): FieldDefinition['data_type'] {
  switch (item?.type) {
    case 'boolean':
      return 'boolean'
    case 'date':
      return 'date'
    case 'double':
    case 'float':
    case 'long':
      return 'number'
    default:
      return 'string'
  }
}

interface FieldDefinitionModalProps extends UseDisclosureProps {
  apps?: Apps
  isOpen: boolean
  recordType: 'account' | 'profile'
  onClose: () => void
  field?: FieldDefinition | null
  onChange?: (field: PersistedFieldDefinition) => void
}

export const dataTypeIcons = {
  string: TextIcon,
  number: IconHash,
  currency: IconCoin,
  boolean: IconToggleLeft,
  date: IconCalendarMonth
}

export function FieldDefinitionModal({ apps, recordType, field, onChange, ...props }: FieldDefinitionModalProps) {
  const { isOpen, onClose } = useDisclosure(props)
  const { mutateAsync: createField, isLoading: creating } = useCreateFieldDefinition()
  const { mutateAsync: updateField, isLoading: updating } = useUpdateFieldDefinition()
  const { data: mappings = [] } = useFieldMappings(
    recordType === 'profile' ? '/profiles/facet-cloud' : '/accounts/facet-cloud',
    { enabled: isOpen }
  )

  // Extract traits from mappings
  const availableFields = useMemo(() => {
    const all = grouped(keyBy(mappings, 'facet'), Object.values(apps || {})).filter((c) => {
      if (c.category.toLowerCase().includes(`${recordType} traits`)) {
        return true
      }

      /**
       * TODO add others...
       * I was hesitant to add any categories from OS
       * because I don't like being tightly coupled to the OS mappings
       * ideally we would have a singular data model that we could hydrate for both OS and PG
       * that we could use for filters, automation mappings, ui, but we're a bit all over the place currently
       * ```
       * account: {
       *   // already have this!
       *   traits: [{ ...traitGroup }],
       *   // don't have this in OS but do have something similar from PG
       *   salesforce: {
       *     account: { fields: { ... } },
       *     opportunity: { fields: { ... }},
       *     contact: { fields: { ... }},
       *     lead: { fields: { ... }},
       *   },
       *   hubspot: {
       *     company: { fields: { ... }},
       *     contact: { fields: { ... }},
       *     deal: { fields: { ... }},
       *   },
       *   // or if we want to leverage flat keys across both places uniformly: `{app}_{object}`
       *   salesforce_account: { fields: { ... }},
       *   salesforce_contact: { fields: { ... }},
       *   // etc.
       * }
       * ```
       */
      if (c.category === 'HubSpot Company' || c.category === 'Salesforce Account') {
        return true
      }

      return false
    })

    const options = all.flatMap((c) => c.items || [])
    return options
  }, [mappings, apps, recordType])

  const isLoading = creating || updating

  const [label, setLabel] = useState(field?.label || '')
  const [description, setDescription] = useState(field?.description)
  const [dataType, setDataType] = useState(field?.data_type || 'string')
  const [dataSource, setDataSource] = useState(field?.data_source || null)
  const [keyField, setKeyField] = useState(field?.key_field || false)

  useEffect(() => {
    if (field) {
      setLabel(field.label)
      setDataType(field.data_type)
      setDataSource(field.data_source || null)
      setKeyField(field.key_field)
    } else {
      setLabel('')
      setDescription(undefined)
      setDataSource('string')
      setKeyField(false)
    }
  }, [isOpen, field])

  const onSubmit = useCallback(async () => {
    try {
      let res: { field: PersistedFieldDefinition }

      if (field?.id) {
        res = await updateField({
          id: field.id,
          field: { label, description, data_type: dataType, data_source: dataSource, key_field: keyField }
        })
      } else {
        res = await createField({
          field: { label, record_type: 'account', data_type: dataType, data_source: dataSource, key_field: keyField }
        })
      }
      onClose()
      onChange?.(res.field)
      toast.success('Field saved successfully!')
    } catch (err) {
      toast.error('Error saving field', { description: (err as any)?.message })
    }
  }, [onClose, onChange, updateField, createField, field?.id, label, description, dataType, dataSource, keyField])

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
      isCentered
    >
      <ModalOverlay backdropFilter="blur(2px)" />
      <ModalContent>
        <ModalCloseButton isDisabled={isLoading} />
        <ModalHeader fontSize="lg">{field ? 'Edit field' : 'Create field'}</ModalHeader>
        <ModalBody>
          <Stack spacing={4}>
            {/* TODO make this optional to support fields you can write to! */}
            <FormControl isRequired>
              <FormLabel>Data source</FormLabel>
              <ComboboxWithSearch
                items={availableFields}
                selectedItem={dataSource ? availableFields.find((t) => t.key === dataSource) || null : null}
                onChange={(selectedItem) => {
                  setDataSource(selectedItem?.key || null)
                  setDataType(deriveType(selectedItem))
                  if (selectedItem?.label) setLabel(selectedItem.label)
                }}
                filterItem={(a, val) =>
                  a?.key?.includes(val.toLowerCase()) ||
                  a?.label?.toLowerCase()?.includes(val.toLowerCase()) ||
                  a?.category?.toLowerCase()?.includes(val.toLowerCase()) ||
                  false
                }
                placeholder="Select the data source..."
                itemToString={(item) => {
                  return [item?.category, item?.label || item?.key || 'Unnamed field'].filter(Boolean).join(': ')
                }}
                itemRenderer={({ item }) => {
                  if (!item) return null
                  return (
                    <Flex alignItems="center" gap={1.5} fontSize="sm" fontWeight="medium" isTruncated>
                      <Iconify flex="none" icon={item.icon} size={15} strokeWidth={2} />
                      <Text flex="1 1 auto" isTruncated>
                        {[item.category, item?.label || item?.key || 'Unnamed field'].filter(Boolean).join(': ')}
                      </Text>
                    </Flex>
                  )
                }}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Field type</FormLabel>
              <SelectInput
                size="sm"
                variant="outline"
                items={Object.keys(dataTypeIcons)}
                selectedItem={dataType}
                popperOptions={{
                  matchWidth: true
                }}
                onSelectedItemChange={({ selectedItem }) => {
                  setDataType(selectedItem)
                }}
                itemRenderer={(item) => {
                  return (
                    <Flex alignItems="center" gap={2} fontSize="sm">
                      <Icon flex="none" as={dataTypeIcons[item]} boxSize={4} color="gray.600" />
                      <Text fontWeight="medium" textTransform="capitalize">
                        {item}
                      </Text>
                    </Flex>
                  )
                }}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Field label</FormLabel>
              <Input size="sm" value={label} onChange={(e) => setLabel(e.target.value)} maxLength={60} />
            </FormControl>
            <FormControl>
              <FormLabel>Description (optional)</FormLabel>
              <AutosizedTextarea
                size="sm"
                placeholder="Describe your field..."
                value={description || ''}
                onChange={(e) => setDescription(e.target.value)}
                autoComplete="off"
                maxRows={8}
              />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter gap={3}>
          <Button size="sm" variant="outline" onClick={onClose} isDisabled={isLoading}>
            Cancel
          </Button>
          <Button size="sm" colorScheme="purple" onClick={onSubmit} isLoading={isLoading}>
            {field?.id ? 'Update field' : 'Create field'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import {
  Box,
  Button,
  ButtonProps,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Portal,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react'
import {
  Icon as TablerIcon,
  IconArrowLeft,
  IconCalendar,
  IconChevronDown,
  IconChevronRight,
  IconChevronUp,
  IconPlus,
  IconUsers,
  IconX
} from '@tabler/icons-react'
import { DurationUnitType } from 'dayjs/plugin/duration'
import keyBy from 'lodash/keyBy'
import sortBy from 'lodash/sortBy'
import React, { useCallback, useMemo, useState } from 'react'
import { FacetFilters, FacetMappings, FacetValue, NumericFilter } from '..'
import dayjs from '../../../../lib/dayjs'
import { AccountView } from '../../../../types/AccountView'
import { Apps } from '../../../../types/App'
import { useAccountViews } from '../../../data/use-account-views'
import { openUpgradeFlow } from '../../../ui/billing-banners/accounts-banner'
import { GrayCard } from '../../../ui/Card'
import { BuildingIcon } from '../../../ui/icons'
import { SearchIcon } from '../../../ui/icons/SearchIcon'
import { useEntitlements } from '../../../ui/useEntitlements'
import { useOverflow } from '../../../ui/useOverflow'
import { VirtualList } from '../../../ui/VirtualList'
import { IndexingEntitlements } from '../../billing/v2'
import { FilterPreviewProps } from '../components/FilterPreview/types'
import { allowCRMFields, Category, categoryGroupings, FilterItem, getItemDisplay, grouped } from './categories'
import {
  CheckboxFilter,
  DateFilter,
  humanize,
  LastSeenFilter,
  SourceFilter,
  quickRanges,
  RadioFilter,
  RangeStatsFilter
} from './filter-cloud'
import MenuItem from './menu-item'

const noop = () => {}

interface Props {
  apps?: Apps
  facetMappings: FacetMappings
  topFilters?: string[]
  facetFilters: FacetFilters
  onSelectFacet?: (facet: string) => void
  setFocusTime: (focusTime: number) => void
  setRange: (range: 'day' | 'week' | 'month' | 'all' | 'any' | null) => void
  setPage: (page: number) => void
  range?: 'day' | 'week' | 'month' | 'all' | 'any' | null
  focusTime?: NumericFilter | null
  children?: React.ReactNode
  selectedFacetKey?: string
  shouldShowCompanyFilters?: boolean
  shouldShowUserAttributeFilters?: boolean
  shouldShowActiveVisitorsFilters?: boolean
  shouldShowIntentFilters?: boolean
  shouldShow3rdPartyFilters?: boolean
  shouldShowStaticListFilters?: boolean
  shouldShowTraits?: boolean
  shouldShowICPFilters?: boolean
  shouldShowLastSeenFilter?: boolean
  shouldAllowFreeEntry?: boolean
  shouldShowListFilters?: boolean
  onFilterChange: (filter: string, value: FacetValue, action: 'add' | 'remove' | 'set') => void
  applyFilters: (appliedFilters: FacetFilters) => void
  toggleFilterOperator?: FilterPreviewProps['toggleFilterOperator']
  facetValuesPath?: string
  formatLabel?: (display: Omit<FilterItem, 'values'> & { appIcon?: string }) => string
  allowedKeys?: string[]
  excludedKeys?: string[]
  colorScheme?: ButtonProps['colorScheme']
  facetCloudLoading?: boolean
  placement?: PopoverProps['placement']
  kind: 'account' | 'profile'
  width?: number | string
  // for controlled use
  isOpen?: boolean
  onOpen?: () => void
  onClose?: () => void
  onToggle?: () => void
  usePortal?: boolean
  hideFacetCounts?: boolean
}

function getPlaceholderForSection(section: string | null) {
  if (typeof section === 'string') {
    return `Search ${humanize(section)}…`
  } else {
    return 'Search attributes to filter…'
  }
}

function CategoryHeader(props: React.PropsWithChildren<{}>) {
  return (
    <Text
      fontSize="12px"
      fontWeight="medium"
      color="gray.500"
      letterSpacing="0.04em"
      textTransform="uppercase"
      paddingX={3}
      {...props}
    />
  )
}

interface CategorySectionProps {
  category: {
    title: string
    subcategories: Category[]
  }
  searchQuery?: string
  selectedCategory?: Category | null
  expandResults?: boolean
  onSelectCategory: (category: string) => void
  onSelectFacet: (facet: string) => void
  formatLabel?: (display: Omit<FilterItem, 'values'> & { appIcon?: string }) => string | React.ReactNode
}

function CategorySection({
  category,
  searchQuery,
  selectedCategory,
  onSelectCategory,
  expandResults,
  onSelectFacet,
  formatLabel
}: CategorySectionProps) {
  if (expandResults || selectedCategory) {
    return (
      <Stack spacing={6}>
        {category.subcategories.map((subcat) => (
          <Stack key={subcat.category} spacing={1.5}>
            {(!selectedCategory || searchQuery) && (
              <Box paddingX={3}>
                <CategoryHeader>{subcat.category}</CategoryHeader>
              </Box>
            )}

            <VirtualList
              maxH={'400px'}
              items={subcat.items}
              estimateSize={() => 36}
              showOverflowFade
              renderItem={(item) => (
                <Box key={item.key} width="100%" px={3}>
                  <MenuItem
                    sectionKey={item.key}
                    icon={item.icon}
                    onClick={() => {
                      onSelectFacet(item.key)
                    }}
                  >
                    {formatLabel ? formatLabel(item) : item.label}
                  </MenuItem>
                </Box>
              )}
            />
          </Stack>
        ))}
      </Stack>
    )
  }

  return (
    <Stack spacing={1.5} paddingX={3}>
      <CategoryHeader>{category.title}</CategoryHeader>
      <Box>
        {category.subcategories.map((subcat) => (
          <MenuItem
            key={subcat.category}
            sectionKey={subcat.category}
            icon={subcat.icon}
            onClick={() => {
              if (subcat.items.length === 1) {
                onSelectFacet(subcat.items[0].key)
              } else {
                onSelectCategory(subcat.category)
              }
            }}
            badge={<Icon as={IconChevronRight} marginLeft="auto" />}
          >
            {subcat.category}
          </MenuItem>
        ))}
      </Box>
    </Stack>
  )
}

export default function FilterPopover(props: Props) {
  const {
    apps = {},
    facetMappings,
    topFilters,
    facetFilters,
    applyFilters,
    onFilterChange,
    range,
    focusTime,
    setFocusTime,
    setRange,
    setPage,
    selectedFacetKey,
    shouldShowCompanyFilters = true,
    shouldShow3rdPartyFilters = true,
    shouldShowUserAttributeFilters = true,
    shouldShowActiveVisitorsFilters = true,
    shouldShowIntentFilters = true,
    shouldShowStaticListFilters = true,
    shouldShowTraits = true,
    shouldShowICPFilters = true,
    shouldAllowFreeEntry = false,
    shouldShowListFilters = false,
    formatLabel,
    allowedKeys,
    excludedKeys = [],
    facetCloudLoading,
    toggleFilterOperator,
    placement,
    width = '350px',
    colorScheme = 'purple',
    usePortal = true
  } = props
  const { isOpen, onOpen, onClose } = useDisclosure(props)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [category, setCategory] = useState<string | null>(null)
  const [currentFacetKey, setCurrentFacetKey] = useState<string | null>(selectedFacetKey ?? null)

  const categories = useMemo(() => {
    return grouped(facetMappings, Object.values(apps), allowedKeys, excludedKeys).filter((c) => {
      if (c.category === 'Engagement' && !shouldShowIntentFilters) {
        return false
      }

      if (c.category === 'Lists & Uploads' && !shouldShowStaticListFilters) {
        return false
      }

      if ((c.category === 'Company Attributes' || c.category === 'User Identification') && !shouldShowCompanyFilters) {
        return false
      }

      if (c.category === 'User Attributes' && !shouldShowUserAttributeFilters) {
        return false
      }

      if (c.category === 'Active Visitors' && !shouldShowActiveVisitorsFilters) {
        return false
      }

      if ((c.category === 'Account Traits' || c.category === 'Profile Traits') && !shouldShowTraits) {
        return false
      }

      if (!shouldShow3rdPartyFilters) {
        c.items = c.items.filter(
          (i) => !i.key.startsWith('linkedin') && !i.key.startsWith('github') && !i.key.startsWith('slack')
        )
      }

      if (!shouldShowICPFilters) {
        c.items = c.items.filter((i) => !i.key.startsWith('auto_icp'))
      }

      c.items = c.items.filter((i) => {
        // fiilter out Account or Profile specific filters that don't apply to the type of filter
        if (i.kind && i.kind !== props.kind) {
          return false
        }

        return true
      })

      return true
    })
  }, [
    facetMappings,
    apps,
    shouldShowCompanyFilters,
    shouldShowUserAttributeFilters,
    shouldShowActiveVisitorsFilters,
    shouldShowIntentFilters,
    shouldShowStaticListFilters,
    shouldShowICPFilters,
    shouldShowTraits,
    allowedKeys,
    excludedKeys,
    shouldShow3rdPartyFilters,
    props.kind
  ])

  const onSearchQueryChange = useCallback((e) => {
    setSearchQuery(e.target.value)
  }, [])

  const resetSearchQuery = useCallback(() => {
    setSearchQuery('')
  }, [])

  const onClosePopover = useCallback(() => {
    onClose()
  }, [onClose])

  const onSelectFacet = useCallback((facet: string | null) => {
    setCurrentFacetKey(facet)
    setSearchQuery('')
  }, [])

  const onApplyFilters = useCallback(
    (appliedFilters: FacetFilters) => {
      applyFilters(appliedFilters)
      onClose()
    },
    [applyFilters, onClose]
  )

  // Goes up a level in the filter popover
  const onGoBack = useCallback(
    (e) => {
      e?.stopPropagation()

      if (currentFacetKey) {
        setCurrentFacetKey(null)
      } else {
        setCategory(null)
      }

      setSearchQuery('')
    },
    [currentFacetKey]
  )

  const initialFocusRef = React.useRef<HTMLInputElement | null>(null)

  const onOpenPopover = useCallback(() => {
    setSearchQuery('')
    setCurrentFacetKey(selectedFacetKey ?? null)
    setCategory(!selectedFacetKey && categories.length === 1 ? categories[0].category : null)
    onOpen()
  }, [selectedFacetKey, categories, onOpen])

  // If there is a single facet selected, we'll show it's options instead of the category list
  const currentFacet = useMemo(() => {
    const allFacets = categories.flatMap((c) => c.items)

    if (currentFacetKey === 'range') {
      return {
        key: 'range',
        label: 'Last Seen',
        icon: IconCalendar,
        values: quickRanges
      }
    } else if (currentFacetKey) {
      return allFacets.find((f) => f.key === currentFacetKey) || getItemDisplay(currentFacetKey, Object.values(apps))
    }

    return null
  }, [currentFacetKey, categories, apps])

  const facetValuesPath =
    props.facetValuesPath ?? (props.kind === 'profile' ? '/profiles/facet-values' : '/accounts/facet-values')

  const content = currentFacet ? (
    <FacetOptions
      isOpen={isOpen}
      facet={currentFacet}
      facetMappings={facetMappings}
      facetFilters={facetFilters}
      initialFocusRef={initialFocusRef}
      range={range}
      focusTime={focusTime}
      searchQuery={searchQuery}
      setPage={setPage}
      setRange={setRange}
      setFocusTime={setFocusTime}
      onApplyFilters={onApplyFilters}
      onFilterChange={onFilterChange}
      onGoBack={selectedFacetKey ? undefined : onGoBack}
      onClosePopover={onClosePopover}
      onSearchQueryChange={onSearchQueryChange}
      onResetSearch={resetSearchQuery}
      facetValuesPath={facetValuesPath}
      formatLabel={formatLabel}
      shouldAllowRegexMatching={shouldAllowFreeEntry}
      toggleFilterOperator={toggleFilterOperator}
      hideFacetCounts={props.hideFacetCounts}
    />
  ) : facetCloudLoading ? (
    <Center minHeight="150px">
      <Spinner color="purple.500" thickness="1.5px" />
    </Center>
  ) : (
    <CategoryMenu
      category={category}
      categories={categories}
      topFilters={topFilters}
      initialFocusRef={initialFocusRef}
      searchQuery={searchQuery}
      kind={props.kind}
      setCategory={setCategory}
      shouldShowLists={shouldShowListFilters}
      onListSelect={(view) => {
        onApplyFilters(view.filters.facets ?? {})
      }}
      onGoBack={onGoBack}
      onSearchQueryChange={onSearchQueryChange}
      onSelectFacet={props.onSelectFacet || onSelectFacet}
      resetSearchQuery={resetSearchQuery}
      formatLabel={formatLabel}
    />
  )

  const trigger = props.children || (
    <Button
      size="sm"
      variant="ghost"
      bg={`${colorScheme}.50`}
      _hover={{ bg: `${colorScheme}.100`, color: `${colorScheme}.800` }}
      colorScheme={colorScheme}
      leftIcon={<IconPlus size={14} />}
      iconSpacing={1.5}
    >
      Add Filter
    </Button>
  )

  // use a modal if we are on small screens/mobile
  const isSmall = useBreakpointValue({ base: true, sm: false, md: false })
  if (isSmall) {
    return (
      <>
        <Box display="inline-flex" onClick={onOpenPopover}>
          {trigger}
        </Box>
        <Modal isOpen={isOpen} onClose={onClosePopover} initialFocusRef={initialFocusRef}>
          <ModalOverlay />
          <ModalContent maxHeight={`calc(100% - 7.5rem)`} overflow="hidden">
            <ModalBody color="gray.700" padding={0} minHeight="200px" display="flex" flexDirection="column">
              {content}
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    )
  }

  const PortalTag = usePortal ? Portal : React.Fragment

  return (
    <Popover
      isOpen={isOpen}
      placement={placement || 'bottom-start'}
      initialFocusRef={initialFocusRef}
      onOpen={onOpenPopover}
      onClose={onClosePopover}
      closeOnBlur={currentFacetKey !== 'uniq_page_views' && currentFacetKey !== 'uniq_events'}
      isLazy
      lazyBehavior="keepMounted"
    >
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PortalTag>
        <PopoverContent
          shadow="xl"
          maxHeight="min(620px, calc(85vh - var(--header-height)))"
          maxWidth="min(380px, 92vw)"
          overflow="hidden"
          color="gray.700"
          rootProps={{
            zIndex: 'popover'
          }}
          width={width || '350px'}
          _focus={{
            outline: 'none',
            boxShadow: 'xl'
          }}
        >
          {content}
        </PopoverContent>
      </PortalTag>
    </Popover>
  )
}

interface CategoryMenuProps {
  category: string | null
  categories: Category[]
  topFilters?: string[]
  initialFocusRef: React.RefObject<any>
  searchQuery: string
  setCategory: (category: string | null) => void
  onGoBack: (e: any) => void
  onSearchQueryChange: React.ChangeEventHandler<HTMLInputElement>
  onSelectFacet: (facet: string) => void
  onListSelect?: (list: AccountView) => void
  resetSearchQuery: () => void
  formatLabel?: (display: Omit<FilterItem, 'values'> & { appIcon?: string }) => string
  shouldShowLists?: boolean
  kind: 'account' | 'profile'
}

function CategoryMenu(props: CategoryMenuProps) {
  const {
    category,
    categories,
    topFilters,
    initialFocusRef,
    searchQuery,
    setCategory,
    onGoBack,
    onSearchQueryChange,
    onSelectFacet,
    resetSearchQuery,
    formatLabel
  } = props
  const { scrollRef, overflowTop, overflowBottom } = useOverflow([category])

  const entitlements = useEntitlements() as IndexingEntitlements | undefined

  const topFiltersCategory = useMemo(() => {
    const all = keyBy(
      categories.flatMap((c) => c.items),
      'key'
    )
    const top = topFilters ?? []
    return top.map((facet) => all[facet]).filter(Boolean) as FilterItem[]
  }, [topFilters, categories])

  const topLevelCategories = useMemo(() => {
    let matching = categories

    if (searchQuery.trim()) {
      // sort selected category to top
      matching = sortBy(matching, (c) => c.category !== category)

      const query = searchQuery.trim().toLowerCase()

      // Filter the list of options by label / key
      const results = matching
        .map((c) => {
          const filtered = c.items.filter((item) => {
            const queryParts = humanize(query)
              .split(' ')
              .map((k) => k.toLowerCase())

            // if there's a partial match to the category name
            // and a partial match to the value
            // return true
            if (
              item.category &&
              queryParts.length >= 1 &&
              // if the category matches the first word
              item.category.toLowerCase().includes(queryParts[0]) &&
              // and the rest of the query matches the value
              queryParts.slice(1, queryParts.length).some((k) => item.label.toLowerCase().includes(k))
            ) {
              return true
            }

            return item.label.toLowerCase().includes(query) || item.key.toLowerCase().includes(query)
          })
          return {
            ...c,
            items: filtered
          }
        })
        .filter((c) => c.items.length > 0)

      return [{ title: 'Top Results', subcategories: results }]
    }

    if (category) {
      matching = matching.filter((c) => c.category === category)
    }

    // Ensure that all categories have at least one item, otherwise remove them
    return categoryGroupings(matching.filter((c) => c.items.length >= 1))
  }, [category, categories, searchQuery])

  // Count all results across categories
  const totalResults = topLevelCategories
    .flatMap((c) => c.subcategories.map((c) => c.items.length))
    .reduce((a, b) => a + b, 0)

  const selectedCategory = categories.find((c) => c.category === category)

  return (
    <Flex flexDirection="column" flex="1 1 auto" minHeight="200px" maxHeight="100%">
      {selectedCategory && categories.length > 1 && (
        <HStack borderBottomWidth="1px" paddingX={3} paddingY={2.5} spacing={4} justifyContent="space-between">
          <Button
            size="sm"
            fontSize="xs"
            variant="link"
            _hover={{ textDecoration: 'none', color: 'purple.600' }}
            bg="white"
            color="gray.600"
            onClick={onGoBack}
            leftIcon={<IconArrowLeft size={14} />}
            iconSpacing={1}
          >
            Back
          </Button>

          <Text color="gray.500" fontSize="xs" fontWeight="medium" paddingX={2}>
            {selectedCategory.category}
          </Text>
        </HStack>
      )}

      <Box paddingX={5} paddingTop={5} paddingBottom={2}>
        <InputGroup flex="none" variant="filled" size="sm">
          <InputLeftElement width="9">
            <SearchIcon color="gray.400" size={16} />
          </InputLeftElement>

          <Input
            ref={initialFocusRef}
            value={searchQuery}
            placeholder={getPlaceholderForSection(category)}
            onChange={onSearchQueryChange}
            autoComplete="off"
            name="search"
            rounded="lg"
          />

          {searchQuery && (
            <InputRightElement>
              <IconButton
                size="xs"
                aria-label="Clear search"
                variant="ghost"
                onClick={resetSearchQuery}
                icon={<IconX size={16} />}
              />
            </InputRightElement>
          )}
        </InputGroup>
      </Box>

      <Box position="relative" flex="1 1 auto" minHeight="150px" display="flex" flexDirection="column">
        {selectedCategory && (
          <Text fontSize={'xs'} color="gray.500" px="6" lineHeight={'1.4'}>
            {selectedCategory?.description?.(props.kind)}
          </Text>
        )}
        {searchQuery.trim().length > 0 &&
          selectedCategory &&
          !allowCRMFields(entitlements) &&
          selectedCategory.restricted?.(entitlements) && (
            <GrayCard my="2" p="4" as={Stack}>
              <Heading size="xs">Looking for a custom field?</Heading>
              <Text fontSize="xs">Custom CRM fields are available on our Business plan.</Text>
              <Button onClick={() => openUpgradeFlow('business')} colorScheme="purple" size="sm">
                Talk to Sales
              </Button>
            </GrayCard>
          )}
        <Box ref={scrollRef} maxHeight="min(600px, 100%)" overflow="auto">
          <Box
            position="absolute"
            left={0}
            right={0}
            top={0}
            height="60px"
            zIndex={10}
            bgGradient="linear(to-t, rgba(255,255,255,0.1), #FFFFFF)"
            opacity={overflowTop ? 1 : 0}
            transition="opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
            pointerEvents="none"
          />
          <Box
            position="absolute"
            left={0}
            right={0}
            bottom={0}
            height="60px"
            zIndex={10}
            bgGradient="linear(to-b, rgba(255,255,255,0.1), #FFFFFF)"
            opacity={overflowBottom ? 1 : 0}
            transition="opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
            pointerEvents="none"
          />
          <Stack spacing={2} divider={<Divider />} width="100%">
            {props.shouldShowLists && !selectedCategory && props.onListSelect && (
              <Box paddingX={3} paddingY={3}>
                <ListCategory
                  searchQuery={searchQuery}
                  onSelect={(view) => {
                    props.onListSelect?.(view)
                  }}
                />
              </Box>
            )}
            {!searchQuery.trim() && !selectedCategory && topFiltersCategory.length > 0 && (
              <Box paddingY={3}>
                <CategorySection
                  category={{
                    title: 'Top Filters',
                    subcategories: [{ category: 'Top Filters', items: topFiltersCategory }]
                  }}
                  expandResults
                  onSelectCategory={noop}
                  onSelectFacet={onSelectFacet}
                  formatLabel={formatLabel}
                />
              </Box>
            )}
            {totalResults > 0 ? (
              topLevelCategories.map((c) => (
                <Box key={c.title} paddingY={3}>
                  <CategorySection
                    category={c}
                    searchQuery={searchQuery}
                    selectedCategory={selectedCategory}
                    expandResults={searchQuery.trim() !== ''}
                    onSelectCategory={setCategory}
                    onSelectFacet={onSelectFacet}
                    formatLabel={formatLabel}
                  />
                </Box>
              ))
            ) : (
              <Flex flex="1" justifyContent="center" alignItems="center" minHeight="100px">
                <Text maxWidth="225px" fontSize="sm" color="gray.500" textAlign="center">
                  No matching results. Try searching for a different keyword.
                </Text>
              </Flex>
            )}
          </Stack>
        </Box>
      </Box>
    </Flex>
  )
}

interface FacetOptionsProps {
  isOpen: boolean
  facet: FilterItem
  facetMappings: Props['facetMappings']
  facetFilters: Props['facetFilters']
  initialFocusRef: React.RefObject<any>
  range: Props['range']
  focusTime: Props['focusTime']
  searchQuery: string
  setRange: Props['setRange']
  setFocusTime: Props['setFocusTime']
  setPage: Props['setPage']
  onClosePopover: () => void
  onApplyFilters: (filters: FacetFilters) => void
  onFilterChange: Props['onFilterChange']
  onGoBack?: (e?: any) => void
  onSearchQueryChange: React.ChangeEventHandler<HTMLInputElement>
  onResetSearch?: () => void
  facetValuesPath?: string
  shouldAllowRegexMatching?: boolean
  formatLabel?: (display: Omit<FilterItem, 'values'> & { appIcon?: string }) => string
  toggleFilterOperator: FilterPreviewProps['toggleFilterOperator']
  shouldShowLastSeenFilter?: boolean
  hideFacetCounts?: boolean
}

function FacetOptions(props: FacetOptionsProps) {
  const {
    isOpen,
    facet,
    facetFilters,
    initialFocusRef,
    range,
    focusTime,
    searchQuery,
    setRange,
    setFocusTime,
    onClosePopover,
    onApplyFilters,
    onGoBack,
    onSearchQueryChange,
    onResetSearch,
    formatLabel,
    shouldAllowRegexMatching
  } = props

  return (
    <Box flex="1 1 auto" display="flex" flexDirection="column" height="100%" minHeight="150px">
      <InputGroup variant="flushed">
        <InputLeftElement width="12" overflow="hidden">
          <Box
            position="absolute"
            left={0}
            top={0}
            width="100%"
            height="200%"
            transition="transform 200ms cubic-bezier(0, 0, 0.2, 1)"
            _hover={onGoBack ? { transform: 'translateY(-50%)' } : undefined}
            cursor={onGoBack ? 'pointer' : undefined}
            onClick={onGoBack}
          >
            <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="50%">
              {typeof facet.icon === 'string' ? (
                <Image src={facet.icon} boxSize="16px" />
              ) : (
                <Icon as={facet.icon} color="gray.500" boxSize={4} />
              )}
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="50%">
              <Icon as={IconArrowLeft} color="gray.500" boxSize={4} />
            </Box>
          </Box>
        </InputLeftElement>

        <Input
          value={formatLabel ? formatLabel(facet) : facet.label}
          autoComplete="off"
          name="facet_name"
          borderColor="gray.200"
          _focus={{
            boxShadow: 'none'
          }}
          fontSize="sm"
          fontWeight="medium"
          readOnly
          tabIndex={-1}
        />

        <InputRightElement>
          <IconButton
            size="xs"
            aria-label="Go back"
            variant="ghost"
            onClick={onGoBack || onClosePopover}
            icon={<IconX size={14} />}
          />
        </InputRightElement>
      </InputGroup>

      <Box display="flex" flexDirection="column" alignItems="stretch" overflow="hidden" width="100%" maxHeight="500px">
        {facet.key === 'sources' ? (
          <SourceFilter
            isOpen={isOpen}
            filter={facet.key}
            values={facet.values}
            facetFilters={facetFilters}
            facetValuesPath={props.facetValuesPath}
            onChange={onApplyFilters}
          />
        ) : isRangeStatsFacet(facet) ? (
          <RangeStatsFilter
            isOpen={isOpen}
            onChange={onApplyFilters}
            facetFilters={facetFilters}
            filter={facet.key}
            values={facet.values}
            facetValuesPath={props.facetValuesPath}
          />
        ) : isFitFilter(facet) || isIntentFilter(facet) ? (
          <CheckboxFilter
            isOpen={isOpen}
            filter={facet.key}
            facetFilters={facetFilters}
            onChange={onApplyFilters}
            values={facet.values}
            facetValuesPath={props.facetValuesPath}
            hideCount={props.hideFacetCounts}
          />
        ) : isFocusTime(facet) ? (
          <RangeStatsFilter
            initialUnit="seconds"
            units={['seconds', 'minutes', 'hours']}
            // unit to milliseconds
            convertToQuery={(value, unit) => {
              if (value) {
                return dayjs.duration(value, (unit as DurationUnitType) || 'milliseconds').as('milliseconds')
              } else {
                return value
              }
            }}
            // ms to unit
            convertToValue={(value, unit) => {
              if (value) {
                return dayjs.duration(value, 'milliseconds').as((unit as DurationUnitType) || 'milliseconds')
              } else {
                return value
              }
            }}
            isOpen={isOpen}
            onChange={(filters) => {
              setFocusTime(filters[facet.key])
              onClosePopover()
            }}
            allowedModifiers={['gte', 'lte', 'between', 'exists']}
            facetFilters={{ [facet.key]: focusTime } as FacetFilters}
            filter={facet.key}
            values={facet.values}
            facetValuesPath={props.facetValuesPath}
          />
        ) : isLastSeenFacet(facet) ? (
          <LastSeenFilter
            current={range}
            presets={quickRanges}
            facetFilters={facetFilters}
            facetValuesPath={props.facetValuesPath}
            onChange={(newRange) => {
              onClosePopover()
              setRange(newRange as 'day' | 'week' | 'month' | 'all' | 'any')
            }}
          />
        ) : isDateFacet(facet) ? (
          <DateFilter filter={facet.key} facetFilters={facetFilters} onChange={onApplyFilters} values={facet.values} />
        ) : isBooleanFacet(facet) ? (
          <Flex flexDirection="column" gap={4} paddingY={4} paddingX={4}>
            <RadioFilter
              filter={facet.key}
              facetValuesPath={props.facetValuesPath}
              facetFilters={facetFilters}
              onChange={onApplyFilters}
              values={facet.values}
              hideCount={props.hideFacetCounts}
              isBoolean
            />
          </Flex>
        ) : isNumericFacet(facet) ? (
          <RangeStatsFilter
            isOpen={isOpen}
            onChange={onApplyFilters}
            facetFilters={facetFilters}
            filter={facet.key}
            values={facet.values}
            facetValuesPath={props.facetValuesPath}
          />
        ) : (
          <CheckboxFilter
            isOpen={isOpen}
            initialFocusRef={initialFocusRef}
            searchQuery={searchQuery}
            onSearch={onSearchQueryChange}
            onResetSearch={onResetSearch}
            filter={facet.key}
            facetFilters={facetFilters}
            onChange={onApplyFilters}
            hideCount={props.hideFacetCounts}
            values={facet.values as Record<string, number>}
            operators={facet.operators}
            facetValuesPath={props.facetValuesPath}
            shouldAllowRegexMatching={shouldAllowRegexMatching && facet.allowFreeEntry}
            allowSubstringOperators={facet.allowSubstringOperators}
          />
        )}
      </Box>
    </Box>
  )
}

export function isNumericFacet(facet: FilterItem): boolean {
  if (facet.allowFreeEntry) {
    return false
  }

  if (facet.type && ['float', 'double', 'long'].includes(facet.type)) {
    return true
  }

  return false
}

function isFitFilter(facet: FilterItem): boolean {
  return facet.key.includes('fit_grade_letter')
}

function isIntentFilter(facet: FilterItem): boolean {
  return facet.key.includes('intent_score_level')
}

function isFocusTime(facet: FilterItem): boolean {
  return facet.key === 'focus_time'
}

function isRangeStatsFacet(facet: FilterItem): boolean {
  if (facet.type === 'range_stats') {
    return true
  }

  const keys = Object.keys(facet.values)
  return ['avg', 'min', 'max'].every((key) => keys.includes(key))
}

function isLastSeenFacet(facet: FilterItem): boolean {
  return facet.key === 'range' || facet.key.endsWith('last_seen_at')
}

export function isDateFacet(facet: FilterItem): boolean {
  return facet.type === 'date'
}

function isBooleanFacet(facet: FilterItem): boolean {
  if (facet.allowFreeEntry) {
    return false
  }

  if (facet.type === 'boolean') {
    return true
  }

  const keys = Object.keys(facet.values)
  return keys.length > 0 && keys.every((k) => ['true', 'false', '1', '0'].includes(k))
}

function ListCategory(props: { searchQuery?: string; onSelect: (view: AccountView) => void }) {
  const { data, isLoading } = useAccountViews()

  const accountViews = useMemo(
    () =>
      data?.account_views?.filter((view) => view.name.toLowerCase().includes(props.searchQuery?.toLowerCase() ?? '')) ??
      [],
    [data?.account_views, props.searchQuery]
  )

  const top = useMemo(() => accountViews.slice(0, 3), [accountViews])
  const rest = useMemo(() => accountViews.slice(3, accountViews.length), [accountViews])

  const [isOpen, setIsOpen] = useState(false)

  if (isLoading || data?.account_views.length === 0) {
    return null
  }

  return (
    <Stack spacing={1.5}>
      <CategoryHeader>Lists</CategoryHeader>
      <Box>
        {top.map((view) => (
          <MenuItem
            key={view.id}
            sectionKey={view.id}
            icon={(view.kind === 'account' ? BuildingIcon : IconUsers) as TablerIcon}
            onClick={() => {
              props.onSelect(view)
            }}
          >
            {view.name}
          </MenuItem>
        ))}
        {!isOpen && rest.length > 0 && (
          <MenuItem
            key={'list-more'}
            sectionKey={`Show all`}
            icon={IconChevronDown as TablerIcon}
            onClick={() => {
              setIsOpen(true)
            }}
          >
            Show all
          </MenuItem>
        )}
        {isOpen &&
          rest.map((view) => (
            <MenuItem
              key={view.id}
              sectionKey={view.id}
              icon={(view.kind === 'account' ? BuildingIcon : IconUsers) as TablerIcon}
              onClick={() => {
                props.onSelect(view)
              }}
            >
              {view.name}
            </MenuItem>
          ))}
        {isOpen && rest.length > 0 && (
          <MenuItem
            key={'list-less'}
            sectionKey={'Show less'}
            icon={IconChevronUp as TablerIcon}
            onClick={() => {
              setIsOpen(false)
            }}
          >
            Show less
          </MenuItem>
        )}
      </Box>
    </Stack>
  )
}

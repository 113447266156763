import { Box, Divider, Flex, FlexProps, IconButton, Link, LinkProps, Text, TextProps } from '@chakra-ui/react'
import {
  IconApps,
  IconArrowRightSquare,
  IconBell,
  IconBolt,
  IconBrowser,
  IconChevronLeft,
  IconClick,
  IconClock,
  IconCode,
  IconCreditCard,
  IconCubePlus,
  IconFileTypography,
  IconLayoutGrid,
  IconList,
  IconMail,
  IconMailBolt,
  IconMapSearch,
  IconNotebook,
  IconReport,
  IconSettings,
  IconShieldLock,
  IconSquareKey,
  IconTargetArrow,
  IconTerminal,
  IconUpload,
  IconUserPlus,
  IconUsers,
  IconUserSearch,
  IconVipOff,
  IconWorldBolt
} from '@tabler/icons-react'
import * as React from 'react'
import { useMedia, useMountedState } from 'react-use'
import { usePermission } from './PermissionsContext'
import { projectPath, useCurrentProject } from './ProjectsContext'
import useLocation from './useLocation'

interface SubnavLinkProps extends LinkProps {
  isActive?: boolean
  icon?: React.ReactNode
}

export function SubnavLink({ isActive, children, ...props }: React.PropsWithChildren<SubnavLinkProps>) {
  isActive = isActive ?? location.pathname === props.href

  return (
    <Link
      display="flex"
      gap={2}
      justifyContent="flex-start"
      alignItems="center"
      href={projectPath('/settings')}
      color={isActive ? 'purple.600' : 'gray.800'}
      fontWeight={isActive ? 'semibold' : 'medium'}
      fontSize="13.5px"
      letterSpacing="-0.015em"
      lineHeight="20px"
      style={{ textDecoration: 'none' }}
      _hover={{ bg: 'gray.100' }}
      cursor="pointer"
      paddingY={1.5}
      paddingX={2}
      rounded="md"
      {...props}
    >
      <Box as="span" display="inline-flex" flex="none" color={isActive ? 'purple.600' : 'gray.500'}>
        {props.icon}
      </Box>
      <Text as="span" flex="1" width="100%">
        {children}
      </Text>
    </Link>
  )
}

function GroupHeader(props: React.PropsWithChildren<TextProps>) {
  return <Text fontSize="xs" fontWeight="medium" color="gray.400" letterSpacing="tight" pl={3} pb={1} {...props} />
}

interface SettingsNavContextProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  onToggle: () => void
  // whether or not the sidebar will render offscreen (and requires toggle), i.e. on mobile
  offScreen?: boolean
}

export const SettingsNavContext = React.createContext<SettingsNavContextProps>({
  isOpen: true,
  onOpen: () => {},
  onClose: () => {},
  onToggle: () => {},
  offScreen: false
})

export function useSettingsNav() {
  return React.useContext(SettingsNavContext)
}

interface Props {
  isOpen?: boolean
  collapsible?: boolean
  showBack?: boolean
  layoutProps?: FlexProps
}

export default function SettingsNav(props: Props) {
  const location = useLocation()
  const hideNavURL = React.useMemo(() => location.search.includes('nav=false'), [location.search])
  const project = useCurrentProject()
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const { hasPermission: canManageMembers } = usePermission({ on: 'project', action: 'can_manage_members' })

  const isOpen = props.isOpen ?? true
  const largeScreen = useMedia('(min-width: 768px) and (min-height: 600px)')
  const mounted = useMountedState()

  if (hideNavURL) return null

  return (
    <Flex
      flex="none"
      direction="column"
      minW="250px"
      background="white"
      height={largeScreen ? 'var(--content-height)' : '100%'}
      position={props.collapsible ? 'absolute' : 'sticky'}
      borderRight="1px solid"
      borderColor="gray.200"
      top={0}
      bottom={0}
      left={isOpen || !props.collapsible ? '0px' : '-300px'}
      transition={mounted() && props.collapsible ? 'left 0.2s cubic-bezier(0.4, 0, 0.2, 1)' : undefined}
      {...props.layoutProps}
    >
      {props.showBack && (
        <Flex
          flex="none"
          alignItems="center"
          borderBottom="1px solid"
          borderColor="gray.200"
          px={5}
          height="48px"
          gap={3}
        >
          <IconButton
            aria-label="Exit Settings"
            size="xs"
            variant="ghost"
            color="gray.500"
            icon={<IconChevronLeft size={16} />}
            as="a"
            href={projectPath()}
          />
          <Text fontSize="md" fontWeight="semibold" letterSpacing="tight" userSelect="none" isTruncated>
            Settings
          </Text>
        </Flex>
      )}

      <Flex direction="column" py="6" px="3" pt="6" overflow="auto">
        <GroupHeader>Workspace</GroupHeader>

        <SubnavLink icon={<IconSettings size={18} />} href={projectPath('/settings')}>
          General
        </SubnavLink>
        <SubnavLink
          icon={<IconApps size={18} />}
          href={projectPath('/apps')}
          isActive={location.pathname.includes('/apps')}
        >
          Sources & Integrations
        </SubnavLink>

        <SubnavLink icon={<IconUserPlus size={18} />} href={projectPath('/settings/members')}>
          Members
        </SubnavLink>

        {canManageMembers && (
          <SubnavLink
            icon={<IconShieldLock size={18} />}
            href={projectPath('/settings/security')}
            isActive={location.pathname.includes('/security')}
          >
            Security
          </SubnavLink>
        )}
        {/* TODO hide unless you have teams already (you can achieve this better with Spaces OR Territories) */}
        <SubnavLink
          icon={<IconUsers size={18} />}
          href={projectPath('/settings/teams')}
          isActive={location.pathname.includes('/settings/teams')}
        >
          Teams
        </SubnavLink>
        <SubnavLink
          icon={<IconMapSearch size={18} />}
          href={projectPath('/territories')}
          isActive={location.pathname.includes('/territories')}
        >
          Territories
        </SubnavLink>

        <SubnavLink icon={<IconLayoutGrid size={18} />} href={projectPath('/spaces')}>
          Spaces
        </SubnavLink>

        <SubnavLink icon={<IconList size={18} />} href={projectPath('/views')}>
          Lists
        </SubnavLink>

        <SubnavLink icon={<IconWorldBolt size={18} />} href={projectPath('/settings/utm-ids')}>
          Koala UTM IDs
        </SubnavLink>

        <SubnavLink
          icon={<IconBell size={18} />}
          href={projectPath('/slack-alerts')}
          isActive={location.pathname.includes('/slack-alerts')}
        >
          Slack Alerts
        </SubnavLink>

        {project?.show_automations && (
          <SubnavLink
            icon={<IconMailBolt size={18} />}
            href={projectPath('/auto-outbound')}
            isActive={location.pathname.includes('/auto-outbound')}
          >
            Auto Outbound
          </SubnavLink>
        )}

        <SubnavLink
          icon={<IconUserSearch size={18} />}
          href={projectPath('/settings/prospects')}
          isActive={location.pathname.includes('/prospects')}
        >
          Buyer Personas
        </SubnavLink>

        {project?.show_automations && (
          <SubnavLink
            icon={<IconArrowRightSquare size={18} />}
            href={projectPath('/automations')}
            isActive={location.pathname.includes('/automations')}
          >
            Actions
          </SubnavLink>
        )}

        <Divider my="4" />

        <GroupHeader>Data Management</GroupHeader>

        <SubnavLink
          href={projectPath('/signals')}
          icon={<IconBolt size={18} />}
          isActive={location.pathname.includes('/signals')}
        >
          Intent Signals
        </SubnavLink>
        <SubnavLink icon={<IconReport size={18} />} href={projectPath('/reports/contents')}>
          Content Reports
        </SubnavLink>
        <SubnavLink
          icon={<IconBrowser size={18} />}
          href={projectPath('/utm-reports')}
          isActive={location.pathname.includes('/utm-reports')}
        >
          UTM Reports
        </SubnavLink>
        <SubnavLink
          icon={<IconFileTypography size={18} />}
          href={projectPath('/forms/reports')}
          isActive={location.pathname.startsWith(projectPath('/forms/'))}
        >
          Forms
        </SubnavLink>
        <SubnavLink
          icon={<IconClick size={18} />}
          href={projectPath('/events')}
          isActive={location.pathname.includes('/events')}
        >
          Events
        </SubnavLink>
        <SubnavLink icon={<IconTargetArrow size={18} />} href={projectPath('/scoring')}>
          Scoring
        </SubnavLink>
        <SubnavLink icon={<IconVipOff size={18} />} href={projectPath('/settings/excluded-accounts')}>
          Hidden Accounts
        </SubnavLink>
        <SubnavLink icon={<IconUpload size={18} />} href={projectPath('/imports')}>
          Import History
        </SubnavLink>

        <Divider my="4" />

        <GroupHeader>APIs & Developer</GroupHeader>

        <SubnavLink icon={<IconCode size={18} />} href={projectPath('/settings/install')}>
          Website Tracking
        </SubnavLink>
        <SubnavLink icon={<IconTerminal size={18} />} href={projectPath('/instrumentation')}>
          Developer
        </SubnavLink>
        <SubnavLink icon={<IconSquareKey size={18} />} href={projectPath('/settings/api-keys')}>
          API Keys
        </SubnavLink>
        <SubnavLink icon={<IconNotebook size={18} />} href="https://getkoala.com/docs" isExternal>
          Docs
        </SubnavLink>

        {project?.koala_subscription && !project.koala_subscription.unlimited && canEditProject && (
          <>
            <Divider my="4" />
            <GroupHeader>Billing & Subscription</GroupHeader>
            <SubnavLink icon={<IconCubePlus size={18} />} href={projectPath('/settings/plans')}>
              Plans
            </SubnavLink>
            <SubnavLink icon={<IconCreditCard size={18} />} href={projectPath('/settings/billing')}>
              Billing
            </SubnavLink>
          </>
        )}

        <Divider my="4" />

        <GroupHeader>Your Notifications</GroupHeader>

        <SubnavLink icon={<IconClock size={18} />} href={projectPath('/koala-weekly')}>
          Koala Weekly
        </SubnavLink>

        <SubnavLink icon={<IconMail size={18} />} href={projectPath('/settings/notifications')}>
          Email & Communications
        </SubnavLink>
      </Flex>
    </Flex>
  )
}

import { Heading, HStack, Link, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'
import { StaticList } from '../../../../types/StaticList'
import { User } from '../../../data/use-users'
import { LinkedinBoxIcon } from '../../../ui/icons'
import { projectPath } from '../../../ui/ProjectsContext'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'

export interface TrackedPost {
  id: string
  post_url: string
  reactions_count: number
  comments_count: number
  static_lists: StaticList[]
  slug_name: string
  tracked_by: User
  updated_at: string
}

interface Props {
  trackedPosts: TrackedPost[]
}

export function PostReport(props: Props) {
  return (
    <Stack>
      <Heading size="md" fontWeight="semibold">
        Tracked Posts
      </Heading>
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Post URL</Th>
              <Th>List</Th>
              <Th isNumeric>Reactions Synced</Th>
              <Th isNumeric>Comments Synced</Th>
              <Th isNumeric>Last Updated</Th>
              <Th>Tracked By</Th>
            </Tr>
          </Thead>
          <Tbody fontSize="xs">
            {props.trackedPosts.map((post) => (
              <Tr key={post.id}>
                <Td>
                  <Link color="gray.500" isExternal href={post.post_url} fontWeight="normal">
                    <HStack spacing={2}>
                      <LinkedinBoxIcon color="linkedin.700" size={16} />
                      <TextEllipsis maxW="200px">{post.slug_name}</TextEllipsis>
                    </HStack>
                  </Link>
                </Td>
                <Td>
                  {post.static_lists.map((list) => (
                    <Link key={list.id} color="gray.600" href={projectPath(`/lists/${list.id}`)}>
                      <TextEllipsis maxW="200px">{list.name}</TextEllipsis>
                    </Link>
                  ))}
                </Td>
                <Td isNumeric>{post.reactions_count}</Td>
                <Td isNumeric>{post.comments_count}</Td>
                <Td isNumeric color="gray.500">
                  <TimeAgo time={post.updated_at} />
                </Td>
                <Td color="gray.500">{post.tracked_by?.name}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

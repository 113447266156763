import { TableFooter } from '@app/components/ui/TableFooter'
import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { IconAlignJustified, IconChevronRight, IconDotsVertical, IconLayoutColumns } from '@tabler/icons-react'
import React, { useCallback, useMemo } from 'react'
import router from '../../../lib/router'
import { Account } from '../../../types/Account'
import { Apps } from '../../../types/App'
import { Crm } from '../../../types/Crm'
import { PageMeta } from '../../../types/PageMeta'
import { FacetParams } from '../../data/use-facets'
import { UrlFilterParams, useUrlFilters } from '../../data/use-url-filters'
import { ColumnSelectionDropdown, defaultAccountColumns, useColumns } from '../../ui/ColumnSelector'
import DownloadCsvMenuItem from '../../ui/DownloadCsvButtons'
import { BuildingIcon } from '../../ui/icons'
import { TopBarContent } from '../../ui/TopBarContext'
import PageTitle, { SmallPageHeading } from '../../ui/PageTitle'
import { PartialAccountView, ViewSelector } from '../../ui/ViewSelector'
import { SaveAccountView } from '../account_views/components/AccountViewForm'
import { NewListModal } from '../account_views/components/NewListModal'
import { accountViewPath } from '../account_views/lib/list-paths'
import { AccountList } from '../icps/icp/account-list'
import { ListContainer, useListsNav } from '../lists/components/ListContainer'
import { VisitorTabs } from '../profiles/components/visitor-tabs'
import { AccountEmptyState, NoPixelEmptyState, NoRevealEmptyState } from './components/empty-states/AccountEmptyState'
import { FilterPreview } from './components/FilterPreview'
import { useTrackRecentNavItems } from '../navigation/useTrackRecentNavItems'
import { projectPath } from '../../ui/ProjectsContext'

type RangeString = `${string}..${string}`

export type FacetValue = string | number
export type FacetOperator = 'not' | 'all' | 'gte' | 'lte' | 'lt' | 'gt' | 'exists' | 'prefix' | 'contains'
export type NotFacet = { not: FacetValue[] }
export type AllFacet = { all: FacetValue[] }
export type GteFacet = { gte: FacetValue }
export type LteFacet = { lte: FacetValue }
export type ExistsFacet = { exists: FacetValue }
export type RangeFacet = { gte?: FacetValue; lte?: FacetValue }
export type PrefixFacet = { prefix: FacetValue }
export type ContainsFacet = { contains: FacetValue }
export type Facet =
  | FacetValue[]
  | NotFacet
  | AllFacet
  | GteFacet
  | LteFacet
  | ExistsFacet
  | RangeFacet
  | PrefixFacet
  | ContainsFacet
  | FacetValue
export type FacetCloud = Record<string, Record<string, number>>
export type FacetFilters = Record<string, Facet> | { _or: Array<FacetFilters> }
export type NumericFilter = number | RangeString | RangeFacet | GteFacet | LteFacet

export type MappingDataType = 'boolean' | 'keyword' | 'float' | 'double' | 'ip' | 'long' | 'date'

export interface FacetMapping {
  facet: string
  type: MappingDataType
  label?: string
  values?: {
    [facetValue: string]: number
  }
}

export interface FacetMappings {
  [facet: string]: Omit<FacetMapping, 'facet'>
}

export interface AccountBrowsingProps {
  crm?: Crm
  page_meta: PageMeta
  total_in_workspace?: number
  total_hidden?: number
  facet_filters: FacetFilters
  columns: string[]
  sort_by?: string
  accounts: Account[]
  apps: Apps
  permissions?: Record<'can_edit' | 'can_create' | 'can_destroy', boolean>
  data_flowing: boolean
  has_company_matches: boolean
  selected_range?: FacetParams['range']
}

const allAccountsList = {
  name: 'All Accounts',
  slug: 'all-accounts',
  kind: 'account'
} as const

export default function Index(props: AccountBrowsingProps) {
  const navContext = useListsNav()
  const facets: UrlFilterParams = useUrlFilters({
    initialRange: props.selected_range ?? 'any',
    initialSortBy: props.sort_by
  })

  const isDirty = !facets.onlyDefaults

  const apps = useMemo(() => Object.values(props.apps), [props.apps])

  const hasCompanies = useMemo(
    () => props.has_company_matches && (props.total_in_workspace ?? 0) > 0,
    [props.has_company_matches, props.total_in_workspace]
  )

  const onRefresh = useCallback(() => {
    router.visit(window.location.toString(), {
      fetch: true
    })
  }, [])

  const { columns, loadingColumns, onColumnChange, onColumnRemove } = useColumns({
    data: props.accounts,
    columns: props.columns?.length ? props.columns : defaultAccountColumns.map((c) => c.key),
    initialColumns: props.columns?.length ? props.columns : defaultAccountColumns.map((c) => c.key)
  })

  const { trackRecentNavItem } = useTrackRecentNavItems()

  const changeView = useCallback(
    (accountView: PartialAccountView | null) => {
      if (accountView?.id && accountView?.slug) {
        const path = accountViewPath(accountView)
        trackRecentNavItem(`accountView:${accountView.id}`)
        router.visit(path)
      } else if (accountView?.id && accountView.class_name === 'StaticList') {
        const path = projectPath(`/lists/${accountView.id}`)
        trackRecentNavItem(`staticList:${accountView.id}`)
        router.visit(path)
      }
    },
    [trackRecentNavItem]
  )

  const newListModal = useDisclosure()

  return (
    <ListContainer>
      <TopBarContent onlyNewNav>
        <Flex alignItems="center" gap={1.5}>
          <SmallPageHeading size="xs" fontWeight="medium">
            Accounts
          </SmallPageHeading>

          <Icon as={IconChevronRight} color="gray.400" boxSize={4} />

          <Box>
            <ViewSelector
              placeholder={allAccountsList}
              kind="account"
              ownership={['private', 'shared']}
              includePrivate
              onChange={changeView}
              onCreateAccountview={newListModal.onOpen}
            />
            <NewListModal {...newListModal} />
          </Box>
        </Flex>
      </TopBarContent>

      <VStack spacing={3} alignItems="stretch">
        <Flex w="100%" alignItems="center" justifyContent="space-between" gap={2}>
          <PageTitle skipRendering>Accounts</PageTitle>
          <HStack flex="1 1 100%" width="100%" justifyContent="space-between" spacing={3}>
            <Flex alignItems="center" gap={3}>
              {navContext.offScreen ? (
                <IconAlignJustified size={20} onClick={navContext.onOpen} />
              ) : (
                <BuildingIcon boxSize={5} />
              )}
              <Heading display="inline-flex" gap={4} alignItems="baseline" size="md">
                Accounts{'  '}
                <Box whiteSpace="nowrap">
                  <Text as="span">{props.page_meta.total_count.toLocaleString()}</Text>
                </Box>
              </Heading>
            </Flex>
          </HStack>

          <Flex gap={2} alignItems="center">
            <VisitorTabs kind="account" />

            <ColumnSelectionDropdown
              audienceKind="account"
              apps={apps}
              selectedColumns={columns}
              onChange={onColumnChange}
            >
              <IconButton
                aria-label="Edit list"
                variant="outline"
                size="sm"
                flex="none"
                icon={<IconLayoutColumns size={18} />}
              />
            </ColumnSelectionDropdown>

            <Menu>
              <MenuButton
                size="sm"
                as={IconButton}
                icon={<IconDotsVertical size={16} />}
                variant="outline"
                borderColor="gray.200"
              />
              <MenuList fontSize="sm" zIndex="popover">
                <DownloadCsvMenuItem
                  url={`${window.location.pathname}/export.csv${window.location.search}`}
                  isMenuDisabled={
                    window.location.pathname.includes('/feed') || window.location.pathname.includes('/live')
                  }
                  apps={apps}
                  audienceKind="account"
                  initialColumns={columns}
                  allowColumnSelection
                />
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        <Flex justifyContent="space-between" gap={4}>
          <FilterPreview {...facets} kind="account" apps={props.apps} />

          {isDirty && (
            <Box marginLeft="auto">
              <SaveAccountView type="account" isFiltering={facets.isFiltering} permissions={props.permissions} />
            </Box>
          )}
        </Flex>

        {props.accounts.length > 0 && (
          <Box>
            <AccountList
              accounts={props.accounts}
              facetParams={facets}
              apps={apps}
              crm={props.crm}
              range={facets.range ?? 'week'}
              sortingBy={facets.sortBy ?? 'overall_grade'}
              onRefresh={onRefresh}
              onSortChange={facets.setSortBy}
              columns={columns}
              loadingColumns={loadingColumns}
              canAddColumns
              onColumnChange={onColumnChange}
              onColumnRemove={onColumnRemove}
            />

            <TableFooter
              word="account"
              pageMeta={props.page_meta}
              page={(facets.page ?? 1) as number}
              setPage={facets.setPage}
              sticky
            />
          </Box>
        )}
      </VStack>

      {props.accounts.length === 0 && props.data_flowing && hasCompanies && <AccountEmptyState facets={facets} />}
      {props.accounts.length === 0 && !props.data_flowing && <NoPixelEmptyState />}
      {props.accounts.length === 0 && props.data_flowing && !hasCompanies && <NoRevealEmptyState />}
    </ListContainer>
  )
}
